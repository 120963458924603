<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">订单管理</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-row>
      <v-col :cols="2">
        <v-text-field dense outlined solo label="订单号" @input="changeSearch" v-model="order_id">

        </v-text-field>
      </v-col>
      <v-col :cols="2">
        <v-select dense outlined solo loader-height="800" label="订单状态" return-object :items="orderStatus" v-model="status"
                  @change="changeStatus">

        </v-select>
      </v-col>
      <v-col :cols="2">
        <v-btn color="primary" @click="query">查询</v-btn>
        <v-btn color="primary" @click="reset" class="ml-2">重置</v-btn>
      </v-col>
    </v-row>

    <v-card flat min-height="300" tile>
      <!--      <v-card-title>-->
      <!--        <v-text-field-->
      <!--            v-model="search"-->
      <!--            append-icon="mdi-magnify"-->
      <!--            label="Search"-->
      <!--            single-line-->
      <!--            hide-details-->
      <!--        ></v-text-field>-->
      <!--      </v-card-title>-->
      <v-data-table
          :headers="headers"
          :items="desserts"
      >
        <template v-slot:item.order_info="{ item }">
          <div class="d-flex ma-2">
            <div style="flex: 3" class="mr-2">
              <v-img :aspect-ratio="1.5/2" width="60" :src="item.order_info[0].book.dimensional+randomPath">

              </v-img>
            </div>
            <div style="flex: 7" class="d-flex flex-column justify-space-between">
              <div>{{ item.order_info[0].book.title }}</div>
              <div>
                <span>¥&nbsp;&nbsp;{{
                    item.order_info[0].book.discount_price
                  }}</span><span>&nbsp;&nbsp;&nbsp;&nbsp;x {{ item.order_info[0].quantity }}</span>
              </div>
            </div>
          </div>
          <div v-if="isActiveId===item.id">
            <div class="d-flex ma-2" v-for="i in item.order_info.slice(1,item.order_info.length)" :key="i.book.id">
              <div style="flex: 3" class="mr-2">
                <v-img :aspect-ratio="1.5/2" width="60" :src="i.book.dimensional+randomPath">

                </v-img>
              </div>
              <div style="flex: 7" class="d-flex flex-column justify-space-between">
                <div>{{ i.book.title }}</div>
                <div>
                  <span>¥&nbsp;&nbsp;{{ i.book.discount_price }}</span><span>&nbsp;&nbsp;&nbsp;&nbsp;x {{ i.quantity }}</span>
                </div>
              </div>
            </div>
          </div>
          <v-chip label color="primary" class="ma-2" small v-if="item.order_info.length>1" @click="getMore(item)">
            {{ isActiveId === item.id ? '收起' : '展开' }}
          </v-chip>
        </template>
        <template v-slot:item.order_status="{ item }">
          <div v-html="getStatus(item.order_status)">
          </div>
        </template>
        <template v-slot:item.create_time="{ item }">
          {{ item.create_time | dateFormat('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-slot:item.action="{item}">
          <v-btn @click="showDetail(item)" outlined small>
            详情
          </v-btn>
          <v-btn v-if="item.order_status==='2'" outlined small color="rgb(24,103,192)" class="ml-2"
                 @click="showShipping(item)">
            发货
          </v-btn>
          <v-btn v-if="item.order_status==='3'" outlined small color="rgb(24,103,192)" class="ml-2"
                 @click="showShipping(item)">
            修改物流号
          </v-btn>
          <v-btn v-if="item.order_status==='5'" outlined small color="rgb(0,188,212)" class="ml-2"
                 @click="showRefunds(item)">
            退款
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <div v-if="refresh" class="pagination">
      <v-pagination
          v-if="desserts.length > 0"
          v-model="search.page"
          :length="pageCount"
          @input="(e) => doGet(e)"
      ></v-pagination>
    </div>
    <v-dialog persistent transition="dialog-bottom-transition" max-width="800" v-model="detail_dialog">
      <v-card>
        <v-card-title>
          订单信息
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="2" class="ml-8">
              订单编号
            </v-col>
            <v-col :cols="9">
              {{ good.local_order_number }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              订单创建时间
            </v-col>
            <v-col :cols="9">
              {{ good.create_time | dateFormat('YYYY-MM-DD HH:mm') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              订单状态
            </v-col>
            <v-col :cols="9">
              <div v-html="getStatus(good.order_status)">
              </div>
            </v-col>
          </v-row>
          <v-row v-if="good.order_status==='5'||good.order_status==='6'">
            <v-col :cols="2" class="ml-8">
              退款理由
            </v-col>
            <v-col :cols="9">
              {{ good.reason }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          商品信息
        </v-card-title>
        <v-card-text>
          <v-row v-for="item in good.order_info" :key="item.id">
            <v-col :cols="2" class="ml-8">
              <v-img :aspect-ratio="1.5/2" width="80" :src="item.book.dimensional+randomPath">

              </v-img>
            </v-col>
            <v-col :cols="9">
              <div class="d-flex flex-column justify-space-between" style="height: 100%">
                <div>{{ item.book.title }}</div>
                <div>
                  <span>¥&nbsp;&nbsp;{{
                      item.book.discount_price
                    }}</span><span>&nbsp;&nbsp;&nbsp;&nbsp;x {{ item.quantity }}</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              商品总价
            </v-col>
            <v-col :cols="9">
              ¥&nbsp;&nbsp;{{ totalPrice(good.order_info) }}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              实付款
            </v-col>
            <v-col :cols="9">
              ¥&nbsp;&nbsp;{{ totalPrice(good.order_info) }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          收货信息
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="2" class="ml-8">
              收货人
            </v-col>
            <v-col :cols="9">
              {{getMes(good.address,'userName')}}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              联系方式
            </v-col>
            <v-col :cols="9">
              {{getMes(good.address,'telNumber')}}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              收货地址
            </v-col>
            <v-col :cols="9">
              {{getMes(good.address,'all')}}
            </v-col>
          </v-row>
        </v-card-text>
        <template v-if="good.order_status==='3'">
          <v-divider></v-divider>
          <v-card-title>
            物流信息
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col :cols="2" class="ml-8">
                物流公司
              </v-col>
              <v-col :cols="9">
                {{good.expresswaybill.express_company}}
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="2" class="ml-8">
                物流单号
              </v-col>
              <v-col :cols="9">
                {{good.expresswaybill.express_waybill}}
              </v-col>
            </v-row>
          </v-card-text>
        </template>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="detail_dialog=false"
          >
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent transition="dialog-bottom-transition" max-width="800" v-model="shipping_dialog">
      <v-card>
        <v-card-title>
          订单发货
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="2" class="ml-8">
              收货人
            </v-col>
            <v-col :cols="9">
              {{getMes(good.address,'userName')}}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              联系方式
            </v-col>
            <v-col :cols="9">
              {{getMes(good.address,'telNumber')}}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              收货地址
            </v-col>
            <v-col :cols="9">
              {{getMes(good.address,'all')}}
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              物流公司
            </v-col>
            <v-col :cols="9">
              <v-select dense outlined style="width: 300px" return-object :items="couriers" v-model="courier"
                        hide-details="auto">

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="2" class="ml-8">
              物流单号
            </v-col>
            <v-col :cols="9">
              <v-text-field dense outlined style="width: 300px" hide-details="auto" v-model="courier_number" :hint="courier.type==='SFEXPRESS' ? '顺丰请输入运单号: 收件人或寄件人手机号后四位。例如：\n'+
'123456789:1234' : ''"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="closeShipping"
          >
            取消
          </v-btn>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="sendExpress"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent transition="dialog-bottom-transition" max-width="800" v-model="refunds_dialog">
      <v-card>
        <v-card-title>
          确认退款
        </v-card-title>
        <v-card-text>
          是否确认退款？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="refunds_dialog=false"
          >
            取消
          </v-btn>
          <v-btn
              color="deep-purple lighten-2"
              text
              @click="refunds_dialog=false"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {get_order_list, get_only, del_order} from "../../api/orders";
import {send_express,edit_express} from "../../api/express";
import _ from "lodash";

export default {
  name: "index",
  data() {
    return {
      refresh: true,
      verson:Math.random(),
      search: {
        page: 1,
        pagesize: 10,
        search: ''
      },
      total: 0,
      headers: [
        {
          text: '购买商品',
          value: 'order_info'
        },
        {
          text: '支付状态',
          value: 'order_status'
        },
        {text: '订单创建时间', value: 'create_time'},
        {
          text: '本地订单号',
          value: 'local_order_number'
        },
        {
          text: '用户id',
          value: 'user'
        },
        {text: '操作', value: 'action'}
      ],
      desserts: [],
      orderStatus: [
        {
          text: '交易关闭',
          value: 0
        },
        {
          text: '待付款',
          value: 1
        },
        {
          text: '待发货',
          value: 2
        },
        {
          text: '待收货',
          value: 3
        },
        {
          text: '待评价',
          value: 4
        },
        {
          text: '退款/售后',
          value: 5
        },
        {
          text: '已退款',
          value: 6
        },
        {
          text: '已完成',
          value: 7
        },
      ],
      status: {},
      statusObj: {
        '0': {
          status_str: '已关闭',
          status_color: 'rgb(153,153,153)'
        },
        '1': {
          status_str: '待付款',
          status_color: 'rgb(255,0,0)'
        },
        '2': {
          status_str: '待发货',
          status_color: 'rgb(24,103,192)'
        },
        '3': {
          status_str: '待收货',
          status_color: 'rgb(251,140,0)'
        },
        '4': {
          status_str: '待评价',
          status_color: 'rgb(76,175,80)'
        },
        '5': {
          status_str: '待售后',
          status_color: 'rgb(0,188,212)'
        },
        '6': {
          status_str: '已退款',
          status_color: 'rgb(233,30,99)'
        },
        '7': {
          status_str: '已完成',
          status_color: 'rgb(233,30,99)'
        }
      },
      order_id: '',

      couriers: [
        {
          text: '顺丰快递',
          type:'SFEXPRESS',
          value: 0
        },
        {
          text: '韵达快递',
          type:'YUNDA',
          value: 1
        },
        {
          text: '中通快递',
          type:'ZTO',
          value: 2
        },
        {
          text: '圆通快递',
          type:'YTO',
          value: 3
        },
        {
          text: '百世快递',
          type:'HTKY',
          value: 4
        },
        {
          text:'天天快递',
          type:'TTKDEX',
          value:5
        },
        {
          text: '邮政包裹',
          type:'CHINAPOST',
          value:5
        },
        {
          text:'申通快递',
          type:'STO',
          value: 6
        }

      ],
      courier: {},
      courier_number:'',

      detail_dialog: false,
      shipping_dialog: false,
      refunds_dialog: false,
      good: {},
      more: false,
      isActiveId: 0,

      randomPath:'?t='+Math.random(),
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
    getStatus() {
      return function (status) {
        if (this.statusObj[status]) {
          return `<span style="color: ${this.statusObj[status].status_color};font-weight: bold">${this.statusObj[status].status_str}</span>`
        } else
          return '未知状态'
      }
    },
    totalPrice(){
      return function (goods) {
        let totalPrice=0
        if (goods){
          totalPrice=goods.reduce((acc,cur)=>{
            return acc+cur?.book?.discount_price*cur?.quantity
          },0)
        }
        return totalPrice
      }
    },
    getMes(){
      return function (address,info){
        let mes=''
        if (address){
          address=address.replaceAll("'",'"')
          try {
            if (typeof JSON.parse(address)==='object'){
              mes=JSON.parse(address)?.[info]
            }
          }catch (e){
            mes='未知'
          }
        }
        return mes
      }
    },
  },
  mounted() {
    this.doGet()
  },
  methods: {
    async doGet(page = this.search.page) {
      const {results, count} = await get_order_list({...this.search, page})
      this.desserts = results;
      console.log('数据', this.desserts)
      this.total = count;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
      this.desserts.length && this.desserts.map(item => item.order_info = eval(item.order_info))
    },
    changeSearch: _.debounce(function (e) {
      this.search.search = e
      this.search.page = 1
      this.doGet()
    }, 500),

    async deleteItem(id) {
      await del_order(id)
      await this.doGet()
    },
    reset() {
      this.order_id = ''
      delete this.search.steps
      this.status = {}
      this.doGet()
    },
    changeStatus() {
      const {value} = this.status
      this.search.steps = value
      this.search.page = 1
      this.doGet()
    },
    query() {
      this.search.page = 1
      this.doGet()
    },
    showDetail(item) {
      this.detail_dialog = true
      this.good = item
      console.log(item)
    },
    showShipping(item) {
      this.shipping_dialog = true
      this.good = item
      if (this.good?.expresswaybill?.express_waybill){
        this.courier_number=this.good.expresswaybill.express_waybill
        console.log(this.good.expresswaybill.express_company)
        const data=this.couriers.find(item=>item.type===this.good.expresswaybill.express_company)
        console.log(data)
        this.courier=data.value
      }
      console.log(this.good)
    },
    showRefunds(item) {
      this.refunds_dialog = true
      this.good = item
    },
    getMore(item) {
      this.isActiveId === item.id ? this.isActiveId = 0 : this.isActiveId = item.id
    },
    async sendExpress(){
      const {id,order_status}=this.good
      const {type}=this.courier
      if (order_status==='2'){
        if (type||this.courier_number){
          const data=await send_express({
            order:id,
            express_company:type,
            express_waybill:this.courier_number
          })
          data&&this.$snackbar({
            content:'订单已发货',
            color: 'success',
            showClose: true
          })
          this.shipping_dialog=false
          this.courier_number=''
          this.courier={}
          this.query()
        }else {
          this.$snackbar({
            content:'请选择物流公司并填写物流单号',
            color: 'error',
            showClose: true
          })
        }
      }else {
        const {expresswaybill:{id:express_id}}=this.good
        console.log(express_id)
        if (type||this.courier_number){
          const data=await edit_express({
            id:express_id,
            order:id,
            express_company:type,
            express_waybill:this.courier_number
          })
          data&&this.$snackbar({
            content:'物流信息已更改',
            color: 'success',
            showClose: true
          })
          this.shipping_dialog=false
          this.courier_number=''
          this.courier={}
          this.query()
        }else {
          this.$snackbar({
            content:'请选择物流公司并填写物流单号',
            color: 'error',
            showClose: true
          })
        }
      }


    },
    closeShipping(){
      this.shipping_dialog=false
      this.courier_number=''
      this.courier={}
    }
  }
}
</script>

<style scoped>

</style>